<template>
  <div class="bigindex_box">
    <div class="bigindex_top_box">
      <div class="left_box">
        <div>
          <div class="title_box">
            <div class="title">产值预测</div>
          </div>
          <div id="common_box1" class="left_common_box left_common_box1"></div>
        </div>
        <div>
          <div class="title_box">
            <div class="title">
              行业分析
            </div>
          </div>
          <div id="common_box2" class="left_common_box left_common_box2"></div>
        </div>
        <div>
          <div class="title_box">
            <div class="title">
              企业分析预警
            </div>
          </div>
          <div class="left_common_box left_common_box3">
            <img src="../../assets/images/ta1.png" alt="">
          </div>
        </div>
      </div>
      <div class="center_box">
        <!-- <div class="title_box"></div> -->
        <div class="ta_data_list">
          <div class="ta_data_item">
            <div class="ta_tata_title">
              <div class="title_text">工业总生产值</div>
            </div>
            <div class="ta_tata_content"><span>800</span>亿元</div>
          </div>
          <div class="ta_data_item">
            <div class="ta_tata_title">
              <div class="title_text">工业总生产值增速</div>
            </div>
            <div class="ta_tata_content"><span>30</span>%</div>
          </div>
          <div class="ta_data_item">
            <div class="ta_tata_title">
              <div class="title_text">规上企业数</div>
            </div>
            <div class="ta_tata_content"><span>160</span>家</div>
          </div>
        </div>
        <div class="ta_map_box">
          <div class="ta_map_left">
            <div class="ta_map_item">
              <div class="ta_map_item_title">厂房卫星图</div>
              <div class="ta_map_item_img">
                <img src="../../assets/images/ta2.png" alt="">
              </div>
            </div>
            <div class="ta_map_item">
              <div class="ta_map_item_title">生产线实景</div>
              <div class="ta_map_item_img">
                <img src="../../assets/images/ta3.png" alt="">
              </div>
            </div>
            <div class="ta_map_item">
              <div class="ta_map_item_title">企业经营指标</div>
              <div class="ta_map_item_img">
                <img src="../../assets/images/ta4.png" alt="">
              </div>
            </div>
          </div>
          <div class="ta_map_right">
            <div class="ta_map_right_title">产业全景地图</div>
            <div class="ta_map_right_img">
              <img src="../../assets/images/ta_map.png" alt="">
            </div>
          </div>
        </div>
        <div class="ta_map_legend">
          <div class="ta_map_legend_title">八大支<br/>柱产业</div>
          <div class="ta_map_legend_main">
            <span class="ta_map_legend_item"><img src="../../assets/images/ball1.png" alt="">电子及通讯设备</span>
            <span class="ta_map_legend_item"><img src="../../assets/images/ball2.png" alt="">汽车</span>
            <span class="ta_map_legend_item"><img src="../../assets/images/ball3.png" alt="">化学原料</span>
            <span class="ta_map_legend_item"><img src="../../assets/images/ball4.png" alt="">石油化工</span>
            <span class="ta_map_legend_item"><img src="../../assets/images/ball5.png" alt="">电力和热力</span>
            <span class="ta_map_legend_item"><img src="../../assets/images/ball6.png" alt="">食品和饮料</span>
            <span class="ta_map_legend_item"><img src="../../assets/images/ball7.png" alt="">金属冶炼及加工业</span>
            <span class="ta_map_legend_item"><img src="../../assets/images/ball8.png" alt="">电器机械及器材</span>
          </div>
        </div>
      </div>

      <div class="left_box">
        <div>
          <div class="title_box">
            <div class="title">
              工业互联网成熟度应用水平
            </div>
          </div>
          <div id="common_box3" class="left_common_box left_common_box3"></div>
        </div>
        <div>
          <div class="title_box">
            <div class="title">系统集成水平</div>
          </div>
          <div class="left_common_box left_common_box3">
            <div class="ta_percent">
              <div class="percent_title">
                横向集成
              </div>
              <div class="percent_line">
                <el-progress :show-text="false" :stroke-width="10" :percentage="72" />
                <el-progress :show-text="false" :stroke-width="10" :percentage="67" />
              </div>
              <div class="percent_label">
                <div class="percent_label_text">72供应商系统与企业内...</div>
                <div class="percent_label_text">67企业背部IT系统与物...</div>
              </div>
            </div>
            <div class="ta_percent">
              <div class="percent_title">
                纵向集成
              </div>
              <div class="percent_line">
                <el-progress :show-text="false" :stroke-width="10" :percentage="70" status="success" />
                <el-progress :show-text="false" :stroke-width="10" :percentage="63" status="success" />
                <el-progress :show-text="false" :stroke-width="10" :percentage="70" status="success" />
                <el-progress :show-text="false" :stroke-width="10" :percentage="56" status="success" />
              </div>
              <div class="percent_label">
                <div class="percent_label_text">70供应商系统与企业内...</div>
                <div class="percent_label_text">63企业背部IT系统与物...</div>
                <div class="percent_label_text">70供应商系统与企业内...</div>
                <div class="percent_label_text">56企业背部IT系统与物...</div>
              </div>
            </div>
            <div class="ta_percent">
              <div class="percent_title">
                端到谐集成
              </div>
              <div class="percent_line">
                <el-progress :show-text="false" :stroke-width="10" :percentage="45" />
                <el-progress :show-text="false" :stroke-width="10" :percentage="49" />
              </div>
              <div class="percent_label">
                <div class="percent_label_text">45供应商系统与企业内...</div>
                <div class="percent_label_text">49企业背部IT系统与物...</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="title_box">
            <div class="title">数据分析利用水平</div>
          </div>
          <div class="left_common_box left_common_box3">
            <div class="ta_percent">
              <div class="percent_title">
                运营智能决策
              </div>
              <div class="percent_line">
                <el-progress :show-text="false" :stroke-width="10" :percentage="72" status="warning" />
                <el-progress :show-text="false" :stroke-width="10" :percentage="67" status="warning" />
              </div>
              <div class="percent_label">
                <div class="percent_label_text">72智能决策支持系...</div>
                <div class="percent_label_text">67企业背部IT系统与物...</div>
              </div>
            </div>
            <div class="ta_percent">
              <div class="percent_title">
                产品生命周期优化
              </div>
              <div class="percent_line">
                <el-progress :show-text="false" :stroke-width="10" :percentage="70" status="success" />
                <el-progress :show-text="false" :stroke-width="10" :percentage="63" status="success" />
              </div>
              <div class="percent_label">
                <div class="percent_label_text">70供应商系统与企业内...</div>
                <div class="percent_label_text">63企业背部IT系统与物...</div>
              </div>
            </div>
            <div class="ta_percent">
              <div class="percent_title">
                供应链优化
              </div>
              <div class="percent_line">
                <el-progress :show-text="false" :stroke-width="10" :percentage="45" />
                <el-progress :show-text="false" :stroke-width="10" :percentage="49" />
              </div>
              <div class="percent_label">
                <div class="percent_label_text">45供应商系统与企业内...</div>
                <div class="percent_label_text">49企业背部IT系统与物...</div>
              </div>
            </div>
            <div class="ta_percent">
              <div class="percent_title">
                网络协同
              </div>
              <div class="percent_line">
                <el-progress :show-text="false" :stroke-width="10" :percentage="45" status="exception" />
                <el-progress :show-text="false" :stroke-width="10" :percentage="49" status="exception" />
              </div>
              <div class="percent_label">
                <div class="percent_label_text">45供应商系统与企业内...</div>
                <div class="percent_label_text">49企业背部IT系统与物...</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let datas1=[
        { value: 36, name: "电子及通讯设计波造" },
        { value: 22, name: "汽车造业" },
        { value: 25, name: "化学料及化学品洁业" },
        { value: 17, name: "电器机械及器材" },
      ]
let datas2=[
  { value: 10, name: "尚未启动" },
  { value: 22, name: "刚刚起步" },
  { value: 25, name: "逐步推进" },
  { value: 17, name: "比较完善" },
  { value: 26, name: "水平领先" },
]
export default {
  components: {},
  data() {
    return {
      cmpItemHeight: "",
      cmpOption: {
        limitMoveNum: 4, //开始无缝滚动最小数据长度，默认是5
        direction: 1, //方向 0 往下 1 往上 2向左 3向右
        // singleHeight: 222,   //单个停止高度（默认为零无缝）=>方向0/1
        // waitTime: 2500,   //单步停止等待时间(默认值1000ms)
        step: 0.5, //数字越大滚动越快
        hoverStop: false,
        autoPlay: true
      },
      dataList: [
        {
          tyoe:'手机',
          title: "纠纷",
          addr: "民事",
          time: "2022年3月18日"
        },
        {
          tyoe:'手机',
          title: "纠纷",
          addr: "民事",
          time: "2022年3月22日"
        },
        {
          tyoe:'手机',
          title: "纠纷",
          addr: "民事",
          time: "2022年7月18日"
        },
        {
          tyoe:'手机',
          title: "纠纷",
          addr: "民事",
          time: "2022年6月18日"
        },
        {
          tyoe:'手机',
          title: "纠纷",
          addr: "民事",
          time: "2022年5月18日"
        },
      ],
      activeIndex: 0,
      tabList: ["三个月", "半年", "一年"],
      timer1: null,
      timer2: null,
      myChart1: {},
      myChart2: {},
      myChart3: {},
      myChart4: {},
      myChart5: {},
      timeValue: "",
    };
  },
  methods: {
    day() {
      var nowDate = new Date();
      var date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        day: nowDate.getDate()
      };
      var dayDate =
        date.year +
        "-" +
        (date.month >= 10 ? date.month : "0" + date.month) +
        "-" +
        (date.day >= 10 ? date.day : "0" + date.day);
      console.log(dayDate);
      this.timeValue = dayDate;
    },
    fontSize(res) {
      const clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return;
      let fontSize = clientWidth / 1920;
      return res * fontSize;
    },
    //党员数量
    getData1() {
      this.myChart1 = this.$echarts.init(
        document.getElementById("common_box1")
      );
      // 绘制图表
      this.myChart1.setOption({
        title: {
          // text: "设备名称：设备盒1",
          textStyle: {
            color: "#fff",
            fontStyle: "normal",
            fontWeight: "100",
            fontSize: 14
          }
        },
        grid: {
          top: "15%",
          bottom: "22%",
          left: "10%"
        },
        tooltip: {
          trigger: "axis"
        },
        xAxis: {
          type: "category",
          data: ["202302","202303","202304","202305","202306","202307",],
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff"
            }
          }
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff"
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#626F86"],
              width: 1,
              type: "dashed"
            }
          }
        },
        series: [
          {
            name: "同比增长",
            type: "line",
            smooth: true,
            lineStyle: {
              color: "#f407f3" //改变折线颜色
            },
            data: [3900, 3500, 2500, 1600, 1000, 800]
          },
          {
            name: "可比价增长",
            type: "line",
            smooth: true,
            lineStyle: {
              color: "#ffbb00" //改变折线颜色
            },
            // emphasis: {
            //   focus: "series"
            // },
            data: [3600, 3200, 2000, 1800, 1600, 1200]
          },
          {
            name: "工业总产值",
            data: [1600,2500,2800,3300,3600,4000],
            type: "bar",
            barWidth: 20, //柱图宽度
            itemStyle: {
              normal: {
                color:"#03a9f4",
              }
            }
          },
        ]
      });
    },
    //温度
    getData2() {
      this.myChart2 = this.$echarts.init(
        document.getElementById("common_box2")
      );
      // 绘制图表
      this.myChart2.setOption({
        tooltip: {
          trigger: "item"
        },
        legend: {
        //   orient: "vertical",
          // icon:'rect',
          itemHeight: 13,
          itemWidth: 13,
          left: "0%", //图例距离左的距离
          bottom:"2%",
        //   y: "center", //图例上下居中
          textStyle: {
            fontSize: 10,
            color: 'rgba(255,255,255,0.8)',
          },
          formatter: function(name) {
            var total = 0;
            var tarValue;
            for (var i = 0; i < datas1.length; i++) {
              total += datas1[i].value;
              if (name === datas1[i].name) {
                tarValue = datas1[i].value;
              }
            }
            var p = Math.round((tarValue / total) * 100);
            return name + "     " + p + "%";
          }
        },
        color: ["#F6BD16", "#2457FF", "#52EEDD", "#667AF6", "#E8684A"],
        series: [
          {
            name: "规模以上工业总产值",
            type: "pie",
            radius: ["30%", "58%"],
            center: ["50%", "35%"], //图的位置，距离左跟上的位置
            avoidLabelOverlap: false,
            label: {
              normal: {
              position: 'inside', // 在内部显示，outseide 是在外部显示
              show: true,
              formatter: (d) => {
                                return  d.value
                            }
		          }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "18",
                fontWeight: "bold"
              }
            },
            labelLine: {
              smooth: 0.2,
                length: 10,
                length2: 20,
              lineStyle: {
                    color: '#fff'
                },
            },
            data: datas1
          }
        ]
      });
    },
    getData3() {
      this.myChart3 = this.$echarts.init(
        document.getElementById("common_box3")
      );
      // 绘制图表
      this.myChart3.setOption({
        tooltip: {
          trigger: "item"
        },
        legend: {
        //   orient: "vertical",
          // icon:'rect',
          itemHeight: 13,
          itemWidth: 13,
          left: "0%", //图例距离左的距离
          bottom:"0%",
        //   y: "center", //图例上下居中
          textStyle: {
            fontSize: 10,
            color: 'rgba(255,255,255,0.8)',
          },
          formatter: function(name) {
            var total = 0;
            var tarValue;
            for (var i = 0; i < datas2.length; i++) {
              total += datas2[i].value;
              if (name === datas2[i].name) {
                tarValue = datas2[i].value;
              }
            }
            var p = Math.round((tarValue / total) * 100);
            return name + "     " + p + "%";
          }
        },
        color: ["#F6BD16", "#2457FF", "#52EEDD", "#667AF6", "#E8684A"],
        series: [
          {
            name: "工业互联网成熟度应用水平",
            type: "pie",
            radius: ["30%", "58%"],
            center: ["50%", "35%"], //图的位置，距离左跟上的位置
            avoidLabelOverlap: false,
            label: {
              normal: {
              position: 'inside', // 在内部显示，outseide 是在外部显示
              show: true,
              formatter: (d) => {
                                return  d.value
                            }
		          }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "18",
                fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
            data: datas2
          }
        ]
      });
    },
    // 疫苗接种情况统计
    getData4() {
      this.myChart4 = this.$echarts.init(
        document.getElementById("common_box4")
      );
      // 绘制图表
      this.myChart4.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none"
          },
          formatter: "{b}:{c}%"
        },
        legend: {},
        xAxis: {
          type: "value",
          show: false,
          min: 0,
          max: 100
        },
        grid: {
          top: "10%",
          bottom: "14%",
          left: "32%"
        },
        yAxis: {
          position: "left",
          type: "category",
          data: ["第三针接种数量","第二针接种数量","第一针接种数量","已接种数量", ],
          axisTick: {
            show: false
          },
          axisLine: {
            // 坐标轴 轴线
            show: false // 是否显示
          },
          axisLabel: {
            color: "#fff",
            fontSize: 13
          }
        },
        series: [
          {
            data: [63, 74, 81, 72],
            type: "bar",
            showBackground: true,
            label: {
              show: true
            },
            itemStyle: {
              normal: {
                barBorderRadius: [6],
                color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "#3462BF"
                  },
                  {
                    offset: 1,
                    color: "#54C7EA"
                  }
                ])
              }
            },
            barCategoryGap: "50%",
            barWidth: "12",
            backgroundStyle: {
              // 边框圆角
              borderRadius: [6],
              color: "#39485D"
            }
          }
        ]
      });
    },
    // 信访人员数量
    getData5() {
      this.myChart5 = this.$echarts.init(
        document.getElementById("common_box5")
      );
      // 绘制图表
      this.myChart5.setOption({
        grid: {
          top: "5%",
          bottom: "15%",
          left: "10%"
        },
        tooltip: {
          trigger: "axis"
        },
        xAxis: {
          type: "category",
          data: [
            "2021-09",
            "2021-10",
            "2021-11",
            "2021-12",
            "2022-01",
            "2022-02",
            "2022-03"
          ],
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff"
            }
          }
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff"
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#626F86"],
              width: 1,
              type: "dashed"
            }
          }
        },
        series: [
          {
            name: "返镇人员数量",
            type: "line",
            smooth: true,
            symbolSize: 8,
            lineStyle: {
              color: "#37D4CE" //改变折线颜色
            },
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(71, 255, 144, 0.41)"
                },
                {
                  offset: 1,
                  color: "rgba(116, 168, 255, 0)"
                }
              ])
            },
            emphasis: {
              focus: "series"
            },
            data: [20, 43, 30, 36, 18, 35, 39]
          }
        ]
      });
    }
  },
  mounted() {
    this.getData1();
    this.getData2();
    this.getData3();
    this.getData4();
    this.getData5();

    window.addEventListener("resize", () => {
      this.myChart1.resize();
      this.myChart2.resize();
      this.myChart3.resize();
      this.myChart4.resize();
      this.myChart5.resize();
    });

    // let cmpBoxH = document.getElementById("cmpListBox").offsetHeight;
    // this.cmpItemHeight = cmpBoxH / this.dataList.length;
  },
  created() {
    // this.day();
  },
  beforeDestroy() {
    // clearInterval(this.timer1); // 清除定时器
    // this.timer1 = null;
    // clearInterval(this.timer2);
    // this.timer2 = null;
  }
};
</script>